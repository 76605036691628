
import { Component, Vue } from 'vue-property-decorator';
// eslint-disable-next-line import/order, import/extensions
import cellRender from './agGrid/CellEditRender.js';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridVue } from 'ag-grid-vue';
import { getComponent, getView } from '@/utils/helpers';
import metaDataModule from '@/store/modules/metaDataModule';
import FuzzySearch from 'fuzzy-search';
import { getNameByEmail } from '@/utils/users';
import accountModule from '@/store/modules/accountModule';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    ActionBuilderForm: () => getView('ActionBuilderForm'),
    FormFilterPopup: () => getView('FormFilterPopup'),
    DatalistDetailPopup: () => getView('DataListDetailPopup'),
    TasqLoader: () => getComponent('loaders/TasqLoader'),
    ConfirmPopup: () => getComponent('tasqsCommon/ConfirmPopup'),
    AgGridVue,
    cellRender,
  },
})
export default class DataListBuilder extends Vue {
  context: any = null;

  searchQuery = '';

  deleteDatalistPopup = false;

  loadingDelete = false;

  datalistID = '';

  dataLoading = true;

  async confirmDeleteAction() {
    this.loadingDelete = true;
    this.rowData = this.rowData.filter((row) => row.id !== this.datalistID);
    await metaDataModule.deleteDatalist({ id: this.datalistID });
    this.datalistID = '';
    this.deleteDatalistPopup = false;
    this.loadingDelete = false;
  }

  datalist: any = {
    id: null,
    title: '',
    lastEditor: '',
    lastEditDate: '',
    formCount: 0,
    required: false,
    newVal: '',
    options: [],
    isActive: true,
    isCustom: true,
  };

  defaultDatalist: any = {
    id: null,
    title: '',
    lastEditor: '',
    lastEditDate: '',
    required: false,
    newVal: '',
    formCount: 0,
    options: [],
    isActive: true,
    isCustom: true,
  };

  activeTasqTab = 'Custom';

  showDatalistDetailPopup = false;

  columnDefs: any = [];

  showFormFilter = false;

  showFormDetail = false;

  defaultColDef = {
    initialWidth: 250,
    sortable: true,
    filter: true,
    editable: false,
    autoHeight: true,
    cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible', 'white-space': 'normal' },
    resizable: true,
  };

  rowData: any = [];

  requiredDataList: any = [];

  cellClick() {
    // console.log(e);
  }

  getRowNodeId: any = null;

  gridApi: any = null;

  gridColumnApi: any = null;

  async closeDatalistPopup() {
    this.showDatalistDetailPopup = false;
    this.datalist = this.defaultDatalist;
  }

  async saveDatalistPopup() {
    this.showDatalistDetailPopup = false;

    this.fetchDatalists();
    this.datalist = {
      id: null,
      title: '',
      lastEditor: '',
      lastEditDate: '',
      required: false,
      newVal: '',
      formCount: 0,
      options: [],
      isActive: true,
      isCustom: true,
    };
  }

  openDatalistPopup() {
    this.datalist = {
      id: null,
      title: '',
      lastEditor: '',
      lastEditDate: '',
      required: false,
      newVal: '',
      formCount: 0,
      options: [],
      isActive: true,
      isCustom: true,
    };
    this.showDatalistDetailPopup = true;
  }

  async onGridReady(params) {
    this.gridApi = params.api;
    // this.rowData = [];
    this.columnDefs = [];
    this.gridColumnApi = params.columnApi;
    this.gridApi.hideOverlay();

    this.columnDefs = [
      {
        field: 'title',
        cellClass: 'noborder',
        cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible', 'white-space': 'normal' },
        sortable: true,
        filter: true,
        width: 550,
        colId: 'title',
        headerName: 'Datalist Name',
      },
      {
        field: 'updatedBy',
        editable: false,
        width: 280,
        colId: 'updatedBy',
        cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible', 'white-space': 'normal' },
        cellClass: 'noborder',
        headerName: 'Latest Editor',
      },
      {
        field: 'updatedAt',
        editable: false,
        width: 280,
        colId: 'updatedAt',
        cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible', 'white-space': 'normal' },
        cellClass: 'noborder',
        headerName: 'Last Edit Date',
      },
      {
        field: 'formCount',
        editable: false,
        width: 280,
        colId: 'formCount',
        cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible', 'white-space': 'normal' },
        cellClass: 'noborder',
        headerName: 'Form Count',
      },
      {
        field: 'Actions',
        colId: 'actions',
        sortable: true,
        pinned: 'right',
        cellClass: 'noborder',
        suppressToolPanel: true,
        cellStyle: { borderBottom: '1px solid rgba(255, 255, 255, 0.1)', overflow: 'visible', 'white-space': 'normal' },
        suppressRowTransform: true,

        editable: false,

        initialWidth: 220,
        cellRendererFramework: 'cellRender',
      },
    ];
  }

  async created() {
    this.dataLoading = true;
    this.context = { componentParent: this };
    await accountModule.getReassignmentList({ useExisting: true });
    await this.fetchDatalists();
  }

  async fetchDatalists() {
    this.dataLoading = true;
    const datalists = await metaDataModule.getDatalist();

    // console.log(datalists);

    this.rowData = datalists
      .map((data) => ({
        title: data.Name,
        updatedAt: new Date(data.ModifiedTime).toDateString(),
        id: data.ID,
        required: data.Required,
        formCount: 0,
        recordType: data.RecordType,
        updatedBy: data.LastModifiedBy ? getNameByEmail(data.LastModifiedBy) || 'Unassign' : '',
      }))
      .filter((row) => row.required !== true && row.recordType === 'PARENT');

    // console.log(this.rowData);

    this.requiredDataList = datalists
      .map((data) => ({
        title: data.Name,
        id: data.ID,
        required: data.Required,
      }))
      .filter((row) => row.required === true);

    // console.log(this.requiredDataList);

    this.dataLoading = false;
  }

  get filteredFormData() {
    const searcher = new FuzzySearch(this.rowData, ['title'], {
      caseSensitive: false,
    });
    return searcher.search(this.searchQuery);
  }

  async editRequiredAction(id) {
    const datalist = this.requiredDataList.find((row) => row.id === id);
    if (datalist) {
      this.datalist.id = id;
      this.datalist.title = datalist.title;
      this.datalist.required = datalist.required;
      const datalistPayload = await metaDataModule.getDatalist(id);
      if (datalistPayload && datalistPayload.length) {
        this.datalist.options = datalistPayload[0].ListItems.map((item) => ({
          title: item.Name,
          isActive: item.Active,
        }));
      }
      this.showDatalistDetailPopup = true;
    }
  }

  async editAction(id) {
    const datalist = this.rowData.find((row) => row.id === id);
    if (datalist) {
      this.datalist.id = id;
      this.datalist.title = datalist.title;
      this.datalist.required = datalist.required;
      const datalistPayload = await metaDataModule.getDatalist(id);
      if (datalistPayload && datalistPayload.length) {
        this.datalist.options = datalistPayload[0].ListItems.map((item) => ({
          title: item.Name,
          isActive: item.Active,
        }));
      }
      this.showDatalistDetailPopup = true;
    }
  }

  get allDatalistTitles() {
    return this.rowData
      .filter((item) => item.title && item.id !== this.datalist.id)
      .map((datalist) => datalist.title.toLowerCase());
  }

  deleteAction(id) {
    this.deleteDatalistPopup = true;
    this.datalistID = id;
  }
}
